import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import {
  StyledDarkRedInformationTypography,
  StyledGreyInfoBox,
  StyledInformationSpan,
  StyledMainBox,
} from "../../../components/commonComponents/StyledComponents/styled";
import UploadButton from "../../../components/commonComponents/FileUpload/UploadButton";
import DateInputField from "../../../components/commonComponents/TextInputs/DateInputs";
import { Close, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { savePicturesInformation } from "../../../api_calls/pictureRequests";
import { updateStateData } from "../../../Store/actions";
import {
  fetchCompanies,
  fetchStoreLevelCompanies,
  fetchAvailableToRetailerIds,
  checkUserDetailsFromCookie,
} from "../../../api_calls/userManagement";
import StoreHierarchy from "./StoreHierarchy";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import ErrorMessageText from "../../../components/commonComponents/Errors/ErrorMessageText";
import { checkStatus, checkTodayDate, formatToStandardDate, standardFormatToday } from "../../../api_calls/utils";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import moment from "moment-timezone";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import { filterRegionsAndStores, autoSelectStoreForPictures, preSelectionOfStores } from "../../../api_calls/campaigns";
import platformConfig from "../../../platformConfig";

const retailerList = ["option 1", "option 2", "option 3"];
const AddNewPicture = ({ open, onClose, size, usedFor, closeModal, pictureinfo }) => {
  // console.log("pictureinfo: ", pictureinfo)
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [showManualBrandCompany, setShowManualBrandCompany] = React.useState(false);

  const checkUserDetails = checkUserDetailsFromCookie();
  let userDetailObj = {};
  if (checkUserDetails.success === true) {
    userDetailObj = checkUserDetails.user_detail;
  }
  const [userInfo, setUserInfo] = React.useState(userDetailObj);

  let availableToUserTypes = platformConfig.availableToUserTypes;

  const handleFieldChange = (picData) => {
    const selectedPicData = typeof currentStateData.new_pic_data !== "undefined" ? currentStateData.new_pic_data : {};
    dispatchAction(
      updateStateData({
        new_pic_data: { ...selectedPicData, ...picData },
      })
    );
  };

  const automaticallySetCompany = () => {
    let data =
      typeof currentStateData.store_level_retailers_list !== "undefined" &&
      currentStateData.store_level_retailers_list.length !== 0
        ? currentStateData.store_level_retailers_list[0]
        : typeof userDetailObj.assigned_company !== "undefined"
        ? userDetailObj.assigned_company
        : [];
    let tree_path = [];
    if (data.length !== 0) {
      tree_path.push(data.tree_path);
      handleFieldChange({
        share_list: tree_path,
        share_list_selection: [data],
      });
    }

    // filterRegionsAndStores();
  };

  React.useEffect(() => {
    // fetchCompanies();
    fetchStoreLevelCompanies();

    // set picture details (if picture info is passed.)
    if (typeof pictureinfo !== "undefined") {
      dispatchAction(
        updateStateData({
          new_pic_data: {
            id: pictureinfo.id,
            content_name: pictureinfo.name,
            pic_start_date: formatToStandardDate(pictureinfo.start_date),
            pic_end_date: formatToStandardDate(pictureinfo.end_date),
            pic_provided_by: { label: pictureinfo.id_advertiser.name, id: pictureinfo.id_advertiser.id },
            pic_start_end_status: checkStatus(pictureinfo.start_date, pictureinfo.end_date),
            // share_list_selection:
            //   typeof pictureinfo.share_list !== "undefined"
            //     ? pictureinfo.share_list.map((list) => {
            //       return {
            //         ...list,
            //         label: list.name,
            //       };
            //     })
            //     : [],
            // share_list:
            //   typeof pictureinfo.share_list !== "undefined"
            //     ? pictureinfo.share_list.map((list) => {
            //       return list.tree_path;
            //     })
            //     : [],
            share_list_selection:
              typeof pictureinfo.share_list !== "undefined" && pictureinfo.share_list !== null
                ? pictureinfo.share_list.map((list) => {
                    return {
                      ...list,
                      label: list.name,
                    };
                  })
                : availableToUserTypes.includes(userInfo.userType)
                ? automaticallySetCompany()
                : [],
            share_list:
              typeof pictureinfo.share_list !== "undefined" && pictureinfo.share_list !== null
                ? pictureinfo.share_list.map((list) => {
                    return list.tree_path;
                  })
                : availableToUserTypes.includes(userInfo.userType)
                ? automaticallySetCompany()
                : [],
          },
        })
      );
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> {usedFor === "update" ? "Edit : " + pictureinfo?.name : "Add Picture"} </DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            <Stack direction={"row"} spacing={4}>
              {/* ------- file upload -------- */}
              <UploadButton uploadButtonId={"pictureUploadButton"} uploadLabel={"Select Picture*"} />
            </Stack>
            {/* ------- error message ------ */}
            {typeof fieldErrors.pic_file !== "undefined" && fieldErrors.pic_file !== "" && (
              <>
                <br />
                <ErrorMessageText message={fieldErrors.pic_file} />
              </>
            )}
            <Box height={20} />
            <StyledGreyInfoBox>
              <Typography variant="body2">
                For optimal system functionality, pictures must have a resolution of 1920x1080 and a file size of less than
                5 MB.
              </Typography>
            </StyledGreyInfoBox>
            <Box height={40} />
            {/* ---------- start/end date ------------- */}
            <Stack direction={"row"} spacing={4}>
              {/* ------ start date -------- */}
              <DateInputField
                fieldRequired={true}
                elemId="startDate"
                label="Start Date*"
                className="start_date_class"
                fieldName="start_date"
                minSelectable={standardFormatToday()}
                disabled={
                  typeof currentStateData.new_pic_data !== "undefined" &&
                  typeof currentStateData.new_pic_data.pic_start_end_status !== "undefined" &&
                  ["Completed", "Active"].includes(currentStateData.new_pic_data.pic_start_end_status)
                    ? true
                    : false
                }
                defaultVal={
                  typeof currentStateData.new_pic_data !== "undefined" &&
                  typeof currentStateData.new_pic_data.pic_start_date !== "undefined"
                    ? currentStateData.new_pic_data.pic_start_date
                    : ""
                }
                showlabel={false}
                // enabledDates={(date) => date.getDay() === 1}
                fieldError={
                  typeof fieldErrors.pic_start_date !== "undefined" && fieldErrors.pic_start_date !== ""
                    ? fieldErrors.pic_start_date
                    : ""
                }
                onChangeCall={(selectedDate) => {
                  handleFieldChange({
                    pic_start_date: selectedDate,
                  });
                }}
              />

              {/* --------end date ------- */}
              <DateInputField
                fieldRequired={true}
                elemId="endDate"
                label="End Date*"
                fieldName="end_date"
                className="end_date_class"
                minSelectable={(() => {
                  if (
                    typeof currentStateData.new_pic_data !== "undefined" &&
                    typeof currentStateData.new_pic_data.pic_start_date !== "undefined" &&
                    moment(currentStateData.new_pic_data.pic_start_date).isSameOrAfter(moment(), "day")
                  ) {
                    return currentStateData.new_pic_data.pic_start_date;
                  } else {
                    return standardFormatToday();
                  }
                })()}
                disabled={
                  typeof currentStateData.new_pic_data !== "undefined" &&
                  typeof currentStateData.new_pic_data.pic_start_end_status !== "undefined" &&
                  ["Completed"].includes(currentStateData.new_pic_data.pic_start_end_status)
                    ? true
                    : false
                }
                defaultVal={
                  typeof currentStateData.new_pic_data !== "undefined" &&
                  typeof currentStateData.new_pic_data.pic_end_date !== "undefined"
                    ? currentStateData.new_pic_data.pic_end_date
                    : ""
                }
                showlabel={false}
                // enabledDates={(date) => date.getDay() === 0}
                fieldError={
                  typeof fieldErrors.pic_end_date !== "undefined" && fieldErrors.pic_end_date !== ""
                    ? fieldErrors.pic_end_date
                    : ""
                }
                onChangeCall={(selectedDate) => {
                  handleFieldChange({
                    pic_end_date: selectedDate,
                  });
                }}
              />
            </Stack>
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {/* -------- Provided by ------- */}
              <SingleSelect
                label="Provided By*"
                enableFullWidth={true}
                elemId={"providedByForPictures"}
                options={typeof currentStateData.brand_list !== "undefined" ? currentStateData.brand_list : []}
                defaultVal={
                  typeof currentStateData.new_pic_data !== "undefined" &&
                  typeof currentStateData.new_pic_data.pic_provided_by !== "undefined"
                    ? currentStateData.new_pic_data.pic_provided_by
                    : {}
                }
                selectionCheck={(option, value) => option.id === value.id}
                onChangeCall={(selectedOption) => {
                  if (selectedOption.id === "new") {
                    setShowManualBrandCompany(true);
                    handleFieldChange({
                      manual_pic_provided_by: true,
                    });
                  } else {
                    setShowManualBrandCompany(false);
                    handleFieldChange({
                      pic_provided_by: selectedOption,
                      manual_pic_provided_by: false,
                    });
                  }
                }}
                fieldError={
                  typeof fieldErrors.provided_by !== "undefined" && fieldErrors.provided_by !== ""
                    ? fieldErrors.provided_by
                    : ""
                }
              />

              {/* ------------- manual brand company name ------------ */}
              {showManualBrandCompany && (
                <TextInputField
                  fieldRequired={true}
                  elemId="brandCompanyName"
                  label="Brand Company Name"
                  fieldName="brand_company_name"
                  defaultVal={
                    typeof currentStateData.new_pic_data !== "undefined" &&
                    typeof currentStateData.new_pic_data.picture_brand_company_name !== "undefined"
                      ? currentStateData.new_pic_data.picture_brand_company_name
                      : ""
                  }
                  showlabel={true}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    e.preventDefault();
                    handleFieldChange({
                      picture_brand_company_name: e.target.value,
                    });
                  }}
                  fieldError={
                    typeof fieldErrors.picture_brand_company_name !== "undefined" &&
                    fieldErrors.picture_brand_company_name !== ""
                      ? fieldErrors.picture_brand_company_name
                      : ""
                  }
                />
              )}

              {availableToUserTypes.includes(userInfo.user_type) ? (
                <MultiSelect
                  label="Available To*"
                  elemId={"availableToDropdown"}
                  enableFullWidth={true}
                  options={
                    typeof currentStateData.store_level_retailers_list !== "undefined"
                      ? currentStateData.store_level_retailers_list
                      : []
                  }
                  defaultVal={
                    typeof currentStateData.new_pic_data !== "undefined" &&
                    typeof currentStateData.new_pic_data.share_list_selection !== "undefined"
                      ? currentStateData.new_pic_data.share_list_selection
                      : []
                  }
                  selectionCheck={(option, value) => option?.id === value?.id}
                  onChangeCall={(selectedOption) => {
                    let userChoices = [];
                    selectedOption.forEach((chosen) => {
                      if (chosen.value !== "all") {
                        userChoices.push(chosen.tree_path);
                      } else {
                        currentStateData.store_level_retailers_list.forEach((retailer) => {
                          userChoices.push(retailer.tree_path);
                        });
                      }
                    });
                    handleFieldChange({
                      share_list: userChoices,
                      share_list_selection: selectedOption,
                    });

                    filterRegionsAndStores("pictures").then((info) => {
                      preSelectionOfStores(pictureinfo, autoSelectStoreForPictures);
                    });
                  }}
                  fieldError={
                    typeof fieldErrors.share_list_selection !== "undefined" && fieldErrors.share_list_selection !== ""
                      ? fieldErrors.share_list_selection
                      : ""
                  }
                />
              ) : (typeof currentStateData?.new_pic_data?.share_list === "undefined" &&
                  typeof currentStateData.store_level_retailers_list !== "undefined") ||
                (currentStateData?.new_pic_data?.share_list.length === 0 &&
                  typeof currentStateData.store_level_retailers_list !== "undefined") ? (
                automaticallySetCompany()
              ) : (
                ""
              )}
            </Stack>
            {/* --------- Stores Selection -------- */}
            <Box height={40} />
            {typeof currentStateData.new_pic_data !== "undefined" &&
            typeof currentStateData.new_pic_data.share_list !== "undefined" &&
            currentStateData?.new_pic_data?.share_list?.length !== 0 ? ( //|| typeof currentStateData?.available_to_selection?.available_to_selection !== "undefined"
              <StoreHierarchy
                fieldNames={{ stores: "picture_stores" }}
                fieldError={
                  typeof fieldErrors.pic_stores !== "undefined" && fieldErrors.pic_stores !== ""
                    ? fieldErrors.pic_stores
                    : ""
                }
                selectionInfo={pictureinfo}
                usedFor={"pictures"}
                actionName={usedFor}
              />
            ) : (
              <></>
            )}
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {usedFor === "update" && (
                <StyledDarkRedInformationTypography variant="body2">
                  Last Modified:
                  <StyledInformationSpan
                    style={{
                      color: "#2D2D37",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                  >
                    {pictureinfo.last_edit_by}
                  </StyledInformationSpan>
                </StyledDarkRedInformationTypography>
              )}
            </Stack>
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setFieldErrors({});
              savePicturesInformation(usedFor).then((info) => {
                if (info.success === false) {
                  if (info.errorMessages !== "") {
                    setFieldErrors(info.errorMessages);
                  }
                } else {
                  closeModal();
                }
              });
            }}
            label={usedFor === "update" ? "Update" : "Save"}
            icon={<Save />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewPicture;
