import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { CustomTextInput } from "../TextInputs/style";
import { StyledCheckbox, StyledMenuItemContainer, StyledMenuListItemText } from "../StyledComponents/styled";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Chip, Typography } from "@mui/material";
import { erase_duplicates } from "../../../api_calls/utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function MultiSelect({
  label,
  defaultVal,
  elemId,
  options,
  onChangeCall,
  fieldError,
  enableFullWidth,
  selectionCheck,
  usedFor,
  onBlurCall,
  showSelectedValues,
  readOnlyFlag,
}) {
  const [selectedValues, setSelectedValues] = React.useState(defaultVal);
  // Use useEffect to update selectedValue when defaultVal changes
  React.useEffect(() => {
    setSelectedValues(defaultVal);
  }, [defaultVal]);

  return (
    <Autocomplete
      id={elemId}
      size="small"
      autoFocus={false}
      multiple
      limitTags={0}
      disableCloseOnSelect
      fullWidth={enableFullWidth}
      disabled={typeof readOnlyFlag !== "undefined" ? readOnlyFlag : false}
      componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
      sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
      options={erase_duplicates(options)}
      filterOptions={(options, params) => {
        const filter = createFilterOptions();
        const filtered = filter(options, params);
        const sortedOptions = filtered.sort((a, b) => {
          const isANumeric = !isNaN(a.label);
          const isBNumeric = !isNaN(b.label);
          if (isANumeric && isBNumeric) {
            // If both a and b are numeric, sort them numerically
            return a.label - b.label;
          } else {
            // If both a and b are non-numeric, sort them alphabetically
            return a.label.localeCompare(b.label);
          }
        });
        if (usedFor !== "filter") {
          return [{ label: "(All)", all: true }, ...sortedOptions];
        } else {
          return [...sortedOptions];
        }
      }}
      value={selectedValues}
      getOptionLabel={(option) => option?.label || ""}
      isOptionEqualToValue={(option, value) => selectionCheck(option, value)}
      onChange={(e, selectedOptions) => {
        const hasSelectAll = selectedOptions.some((option) => option.all);
        // If "Select All" is selected, set all options except "Select All"
        if (hasSelectAll) {
          if (selectedValues.length === options.length) {
            setSelectedValues([]);
            onChangeCall([]);
          } else {
            setSelectedValues(options.filter((option) => !option.all));
            onChangeCall(options.filter((option) => !option.all));
          }
        } else {
          setSelectedValues(selectedOptions);
          onChangeCall(selectedOptions);
        }
      }}
      onBlur={onBlurCall}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <StyledCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={option.all ? !!(selectedValues.length === options.length) : selected}
          />
          <Typography variant="body2" className="selectorOptions">{option.label}</Typography>
        </li>
      )}
      renderInput={(params) => (
        <CustomTextInput
          {...params}
          autoFocus={false}
          autoComplete="off"
          id={elemId + "_cutomTextForDropdown"}
          fullWidth={enableFullWidth}
          sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
          label={label}
          variant="standard"
          error={fieldError !== "" ? true : false}
          helperText={fieldError !== "" ? fieldError : ""}
          // value={getDisplayValue()}
        />
      )}
      renderTags={(value, getTagProps) => {
        const numSelected = value.length;
        if (typeof showSelectedValues !== "undefined") {
          if (showSelectedValues === true) {
            return (
              <Typography variant="body2">
                {selectedValues
                  .map((val) => {
                    return val.label;
                  })
                  .join(", ")}
                {/* +1 Selected */}
              </Typography>
            );
          }
        } else {
          if (numSelected === 1) {
            return (
              <Typography
                variant="body2"
                sx={{ color: typeof readOnlyFlag !== "undefined" && readOnlyFlag === true ? "#62626E" : "#AF3241" }}
              >
                1 Selected
              </Typography>
            );
          } else if (numSelected === options.length) {
            return (
              <Typography
                variant="body2"
                sx={{ color: typeof readOnlyFlag !== "undefined" && readOnlyFlag === true ? "#62626E" : "#AF3241" }}
              >
                All
              </Typography>
            );
          } else if (numSelected > 1) {
            return (
              <Typography
                variant="body2"
                sx={{ color: typeof readOnlyFlag !== "undefined" && readOnlyFlag === true ? "#62626E" : "#AF3241" }}
              >
                {numSelected + " Selected"}
              </Typography>
            );
          } else {
            return (
              (
                <Typography
                  variant="body2"
                  sx={{ color: typeof readOnlyFlag !== "undefined" && readOnlyFlag === true ? "#62626E" : "#AF3241" }}
                >
                  {label}
                </Typography>
              ) || ""
            );
          }
        }
      }}
    />
  );
}
